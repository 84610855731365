import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    width: 60,
    customRender: (_, __, index) => index + 1,
  },
  {
    title: T("移库类型"),
    dataIndex: "type",
    customRender: (_, item) => (item.type === "material_transfer" ? "货品移库" : "整托移库"),
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("质检状态"),
    dataIndex: "status_display",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
  },
  {
    title: T("移出库位"),
    dataIndex: "location_out_number",
  },
  {
    title: T("移入库位"),
    dataIndex: "location_in_number",
  },
  {
    title: T("移库数量"),
    dataIndex: "transfer_quantity",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
